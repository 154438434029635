<template>
  <tfoms-panel
    title="Проверки"
    hide-icon
    small
    v-bind="$attrs"
    :isClickLabel="false"
    style="position: relative"
  >
    <wrapper-loader circle v-if="loading"></wrapper-loader>
    <div class="analysis-tfoms__table analysis-tfoms__base-table">
      <base-table-full
        :dataSource="data"
        :headers="headers1"
        hide-default-footer
        notShowSelect
        nested
        not-filter
        @click:row="click"
        :item-class="
          (item) => (value && item.Id === value.Id ? 'table-item--active' : '')
        "
        :sort-by="['Percent']"
        :sort-desc="[true]"
        :exclude-settings-actions="['table', 'group']"
      >
      </base-table-full>
    </div>
  </tfoms-panel>
</template>
<script>
import moment from "moment";

export default {
  components: {
    BaseTableFull: () => import("@/components/base/BaseTableFull"),
    tfomsPanel: () => import("@/components/analysis/panel.vue"),
    wrapperLoader: () => import("@/components/base/WrapperLoader.vue"),
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
    data: {
      type: Array,
      default: () => [],
    },
    refs: { type: Array, default: null },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      headers1: [
        {
          value: "AuditOperation.Num",
          text: "Номер",
        },
        {
          value: "ExternalOwnerName",
          text: "Организация",
        },
        {
          value: "AuditOperation.Date",
          text: "Дата",
          dataType: "Date",
          displayText: (e) => moment(e, "DD.MM.YYYY").format("MMMM YYYY"),
        },
        {
          value: "Percent",
          text: "Результат",
          dataType: "numberBar",
          options: { append: "%", refs: this.refs },
        },
      ],
    };
  },
  methods: {
    click(val) {
      this.$emit("input", val?.Id === this.value?.Id ? null : val);
    },
  },
};
</script>
